import React from "react";
import { Link } from "gatsby";

import ViewCounter from "src/components/ViewCounter";

import { MDXRenderer } from "gatsby-plugin-mdx";

import {
  MakerHeader,
  Description,
  Pill,
  ArticleWrapper,
  Project,
  MakerShare,
  PostData,
  Date
} from "./styles";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  RedditShareButton,
  RedditIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon
} from "react-share";

const Article = ({ frontmatter, content }) => {
  const { title, quote, id, image, date, user } = frontmatter;
  const url = `https://enlight.nyc/makers/${id}`;

  return (
    <>
      <MakerHeader>
        <div>
          <h1 className="text-3xl tracking-tight leading-none font-extrabold text-gray-800 mt-4">
            {title}{" "}
            <Link to={`/user/${user}`}>
              <small>@{id}</small>
            </Link>
          </h1>

          <Description>{quote}</Description>
          <Pill c="#D1F0FD">Featured Maker</Pill>
        </div>
        <img alt="Featured Maker Image" src={image} />
      </MakerHeader>

      <ArticleWrapper>
        <PostData>
          <Date>{date}</Date>
          <ViewCounter id={id} />
        </PostData>
        <MakerShare>
          <TwitterShareButton url={url} title={title}>
            <TwitterIcon size={40} round />
          </TwitterShareButton>
          <FacebookShareButton url={url} quote={title}>
            <FacebookIcon size={40} round />
          </FacebookShareButton>
          <RedditShareButton url={url} title={title}>
            <RedditIcon size={40} round />
          </RedditShareButton>
          <WhatsappShareButton url={url} title={title}>
            <WhatsappIcon size={40} round />
          </WhatsappShareButton>
          <LinkedinShareButton url={url} title={title}>
            <LinkedinIcon size={40} round />
          </LinkedinShareButton>
          <EmailShareButton url={url} title={title}>
            <EmailIcon size={40} round />
          </EmailShareButton>
        </MakerShare>

        <Project>
          <br /> <br />
          <MDXRenderer>{content}</MDXRenderer>
        </Project>
      </ArticleWrapper>
    </>
  );
};

export default Article;
