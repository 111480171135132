import React, { Component } from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import { PostContainer } from "components/Post/styles";
import FeaturedMaker from "components/Post/FeaturedMaker";

import { MDXRenderer } from "gatsby-plugin-mdx";

class EnlightFeaturedMaker extends Component {
  render() {
    const post = this.props.data.maker;

    return (
      <PostContainer>
        <Helmet
          title={`${post.title} - Featured Maker | Enlight`}
          meta={[
            { name: "description", content: `${post.quote}` },
            {
              name: "og:title",
              content: `Featured Maker: ${post.title}`,
            },
            {
              name: "og:description",
              content: `${post.quote}`,
            },
            {
              name: "og:url",
              content: `https://enlight.nyc/makers/${post.id}`,
            },
            {
              name: "og:image",
              content: `https://enlight.nyc${post.image}`,
            },
            { name: "twitter:card", content: "summary" },
            { name: "twitter:site", content: "@tryenlight" },
            {
              name: "twitter:title",
              content: `Featured Maker: ${post.title}`,
            },
            {
              name: "twitter:url",
              content: `https://enlight.nyc/makers/${post.id}`,
            },
            {
              name: "twitter:description",
              content: `${post.quote}`,
            },
            {
              name: "twitter:image",
              content: `https://enlight.nyc${post.image}`,
            },
          ]}
        />
        <FeaturedMaker frontmatter={post} content={post.mdxBody} />
      </PostContainer>
    );
  }
}

export default EnlightFeaturedMaker;


export const makerPageQuery = graphql`
  query MakerPageQuery($id: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    maker(id: {eq: $id}) {
      title
      user
      quote
      published
      image
      mdxBody
      body
      id
    }
  }
`;
